import { IAccountPatient } from '@/types.ts';
import { getUrl } from '@/utils/api';

function useAccountPatients(accountId: string) {
  const query = getUrl(`v2/accounts/${accountId}/account_patients`);
  return {
    ...query,
    data: query.data as IAccountPatient[],
  };
}

export default useAccountPatients;
