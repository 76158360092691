import { useParams } from 'react-router';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import useAccount from '@/hooks/useAccount';
import AccountPatients from '../AccountPatients/AccountPatients';
import AccountUsers from '../AccountUsers/AccountUsers';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/card';

const Account = () => {
  const { accountId } = useParams();
  const account = useAccount(accountId as string);

  if (account.isLoading || !account.data) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>{account.data.name}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-4">
            <div className="">Account ID: {account.data.id}</div>
            <div className="">Type: {account.data.account_type.name}</div>
            <div className="">Owner: {account.data.owner.name}</div>
          </div>
        </CardContent>
      </Card>

      <hr />

      <div className="mt-4">
        <Tabs defaultValue="patients">
          <TabsList>
            <TabsTrigger value="patients">Patients</TabsTrigger>
            <TabsTrigger value="users">Users</TabsTrigger>
          </TabsList>
          <TabsContent value="patients">
            <AccountPatients accountId={accountId as string} />
          </TabsContent>
          <TabsContent value="users">
            <AccountUsers accountId={accountId as string} />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Account;
