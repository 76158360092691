import useAccountPatients from '@/hooks/useAccountPatients';
import Loading from '../Loading';
import Time from '../Time';
import { Table, TableBody, TableCell, TableCellCentered, TableHead, TableHeader, TableRow } from '../ui/table';

interface AccountPatientsProps {
  accountId: string;
}

const AccountPatients = ({ accountId }: AccountPatientsProps) => {
  const accountPatients = useAccountPatients(accountId);

  if (accountPatients.isLoading || !accountPatients.data) {
    return <Loading />;
  }

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Patient</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Date of Birth</TableHead>
            <TableHead>Added on</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {accountPatients.data.map((accountPatient) => (
            <TableRow key={accountPatient.id}>
              <TableCell>{accountPatient.user.name}</TableCell>
              <TableCell>{accountPatient.user.email}</TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Time>{accountPatient.created_at}</Time>
              </TableCell>
            </TableRow>
          ))}

          {accountPatients.data.length === 0 && (
            <TableRow>
              <TableCellCentered>No patients found.</TableCellCentered>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default AccountPatients;
