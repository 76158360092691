import useAccountUsers from '@/hooks/useAccountUsers';
import Loading from '../Loading';
import Time from '../Time';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';

interface AccountUsersProps {
  accountId: string;
}

const AccountUsers = ({ accountId }: AccountUsersProps) => {
  const accountUsers = useAccountUsers(accountId);

  if (accountUsers.isLoading || !accountUsers.data) {
    return <Loading />;
  }

  return (
    <div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>User</TableHead>
            <TableHead>Role</TableHead>
            <TableHead>Added on</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {accountUsers.data.map((accountUser) => (
            <TableRow key={accountUser.id}>
              <TableCell>{accountUser.user.name}</TableCell>
              <TableCell>{accountUser.role}</TableCell>
              <TableCell>
                <Time>{accountUser.created_at}</Time>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default AccountUsers;
