/* eslint-disable */
// @ts-nocheck
import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import useAccounts from '@/hooks/useAccounts';
import Loading from '../Loading';

// Custom hook to fetch accounts

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
};

const Accounts: React.FC = () => {
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortBy, setSortBy] = useState<'name' | 'email' | 'created_at'>('created_at');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const { data, isLoading } = useAccounts(query, page, perPage, sortBy, sortOrder); // useAccounts to fetch account data

  const navigate = useNavigate();

  const sortedAccounts = useMemo(() => {
    if (!data?.accounts) return [];
    return [...data.accounts].sort((a, b) => {
      switch (sortBy) {
        case 'name': {
          const nameA = a.name || '';
          const nameB = b.name || '';
          return sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
        }
        case 'created_at': {
          const dateA = new Date(a.created_at).getTime();
          const dateB = new Date(b.created_at).getTime();
          return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        }
        default:
          return 0;
      }
    });
  }, [data?.accounts, sortBy, sortOrder]);

  const handleRowClick = (accountId: string) => {
    navigate(`/accounts/${accountId}`);
  };

  const toggleSort = (column: 'name' | 'email' | 'created_at') => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
    setPage(1);
  };

  const SortIndicator: React.FC<{ column: typeof sortBy }> = ({ column }) => (
    <span className={`ml-1 ${column === sortBy ? 'text-purple-600' : 'text-gray-400'}`}>
      {sortBy === column ? (sortOrder === 'asc' ? '▲' : '▼') : '▼'}
    </span>
  );

  return (
    <>
      <div className="container px-4 mx-auto">
        <h1 className="mb-4 text-2xl font-bold">Accounts</h1>

        <input
          type="text"
          placeholder="Search by account name"
          className="w-full p-2 mb-4 border border-gray-300 rounded-md"
          onChange={(e) => {
            setQuery(e.target.value);
            setPage(1);
          }}
        />

        {isLoading && <Loading />}
        {!isLoading && data?.accounts && data.accounts.length > 0 ? (
          <>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead onClick={() => toggleSort('name')} className="cursor-pointer select-none">
                    Account <SortIndicator column="name" />
                  </TableHead>
                  <TableHead onClick={() => toggleSort('email')} className="cursor-pointer select-none">
                    Type <SortIndicator column="email" />
                  </TableHead>
                  <TableHead onClick={() => toggleSort('email')} className="cursor-pointer select-none">
                    Owner <SortIndicator column="email" />
                  </TableHead>
                  <TableHead onClick={() => toggleSort('created_at')} className="cursor-pointer select-none">
                    Created <SortIndicator column="created_at" />
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedAccounts.map((account) => (
                  <TableRow
                    key={account.id}
                    onClick={() => handleRowClick(account.id)}
                    className="cursor-pointer hover:bg-gray-100"
                  >
                    <TableCell>{account.name}</TableCell>
                    <TableCell>{account.account_type?.name}</TableCell>
                    <TableCell>{account.owner?.name}</TableCell>
                    <TableCell>{formatDate(account.created_at)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* Pagination controls go here */}
          </>
        ) : (
          <div className="text-center text-gray-500">No accounts found</div>
        )}
      </div>
    </>
  );
};

export default Accounts;
